import { Button, InputNumber } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Editor, Range } from 'slate';
import { useFocused, useSlate } from 'slate-react';
import { PlaySquareOutlined } from '@ant-design/icons';
import { changeTime } from './util';

const Portal = ({ children }) => {
  return typeof document === 'object' ? createPortal(children, document.body) : null;
};

const SentenceTimestampUpdate = React.memo(({ sentence, index }) => {
  const editor = useSlate();

  if (!sentence) {
    return null;
  }
  return (
    <>
      <InputNumber
        value={sentence.start}
        onChange={(value) => {
          editor.apply({
            type: 'update_sentence_start',
            sentenceIndex: index.current,
            value,
          });
        }}
      />
      <InputNumber
        value={sentence.end}
        onChange={(value) =>
          editor.apply({
            type: 'update_sentence_end',
            sentenceIndex: index.current,
            value,
          })
        }
      />
    </>
  );
});

const DataToolTranscriptToolbar = ({ vidRef }) => {
  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();
  // const [sentence, setSentence] = useState(null);
  const sentenceIndex = useRef();
  const [show, setShow] = useState(false);

  const sentence = editor.selection && editor.children[Range.start(editor.selection).path[0]].sentence;

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (!selection || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
      setShow(false);
      el.removeAttribute('style');
      return;
    }

    const start = Range.start(selection);
    const sentence = editor.children[start.path[0]].sentence;
    console.log({ sentence });

    if (sentence) {
      sentenceIndex.current = start.path[0];
      // document.getElementById("popup-play--text").innerText = " " + wordAtIndex.start;
    }

    try {
      const domSelection = window.getSelection();
      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight - 8}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
    } catch {}
    setShow(true);
  }, [editor.selection, inFocus]);

  return (
    <Portal>
      <div ref={ref} className='transcriptEditor-toolbar'>
        {show && (
          <>
            <Button
              id='popup-play'
              style={{ paddingLeft: 8, paddingRight: 8 }}
              type='default'
              onClick={() => {
                if (sentence) {
                  changeTime(vidRef.current, sentence.start - 0.5);
                }
              }}
              icon={<PlaySquareOutlined style={{ paddingRight: 8 }} />}
            >
              {sentence?.start}
            </Button>
            <SentenceTimestampUpdate index={sentenceIndex} sentence={sentence} />
          </>
        )}
      </div>
    </Portal>
  );
};

export default React.memo(DataToolTranscriptToolbar);
